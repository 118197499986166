import React from 'react';
import { Avatar } from 'components/Avatar';
import { Stack } from 'components/Stack';
import { Heading, Text } from 'components/typography';
import { formatNumber } from 'utils/number';
import tw from 'twin.macro';

export interface SellerBannerProps {
  imgUrl: string;
  avatar: string;
  name: string;
  sold: number;
  desc: string;
}

export const SellerBanner = ({
  imgUrl,
  avatar,
  name,
  sold,
  desc,
}: SellerBannerProps) => {
  return (
    <div>
      <div
        css={tw`h-28 px-4 flex items-center bg-no-repeat bg-center bg-cover`}
        style={{ backgroundImage: `url(${imgUrl})` }}
      >
        <Stack spacing={5} isInline align="center">
          <Avatar src={avatar} hasBorder />
          <div>
            <Heading
              as="h1"
              css={tw`text-lg font-semibold leading-snug text-gray-800`}
              isSerif
            >
              {name}
            </Heading>
            <Text css={tw`text-sm text-gray-500`}>
              {formatNumber(sold)} đã bán
            </Text>
          </div>
        </Stack>
      </div>
      <div css={tw`bg-white p-4`}>
        <Heading as="h6" css={tw`uppercase text-gray-500 mb-1`}>
          Giới thiệu
        </Heading>
        <div
          dangerouslySetInnerHTML={{
            __html: desc,
          }}
          style={{ color: '#525252' }}
        />
      </div>
    </div>
  );
};
