import React from 'react';
import createIcon from './createIcon';

const IconInstagram = createIcon(
  <path
    d="M11.0082 5.61758C8.02695 5.61758 5.62227 8.02227 5.62227 11.0035C5.62227 13.9848 8.02695 16.3895 11.0082 16.3895C13.9895 16.3895 16.3941 13.9848 16.3941 11.0035C16.3941 8.02227 13.9895 5.61758 11.0082 5.61758ZM11.0082 14.5051C9.08164 14.5051 7.50664 12.9348 7.50664 11.0035C7.50664 9.07227 9.07695 7.50195 11.0082 7.50195C12.9395 7.50195 14.5098 9.07227 14.5098 11.0035C14.5098 12.9348 12.9348 14.5051 11.0082 14.5051ZM17.8707 5.39727C17.8707 6.0957 17.3082 6.65352 16.6145 6.65352C15.916 6.65352 15.3582 6.09102 15.3582 5.39727C15.3582 4.70352 15.9207 4.14102 16.6145 4.14102C17.3082 4.14102 17.8707 4.70352 17.8707 5.39727ZM21.4379 6.67227C21.3582 4.98945 20.9738 3.49883 19.741 2.2707C18.5129 1.04258 17.0223 0.658203 15.3395 0.573828C13.6051 0.475391 8.40664 0.475391 6.67227 0.573828C4.99414 0.653516 3.50352 1.03789 2.2707 2.26602C1.03789 3.49414 0.658203 4.98477 0.573828 6.66758C0.475391 8.40195 0.475391 13.6004 0.573828 15.3348C0.653516 17.0176 1.03789 18.5082 2.2707 19.7363C3.50352 20.9645 4.98945 21.3488 6.67227 21.4332C8.40664 21.5316 13.6051 21.5316 15.3395 21.4332C17.0223 21.3535 18.5129 20.9691 19.741 19.7363C20.9691 18.5082 21.3535 17.0176 21.4379 15.3348C21.5363 13.6004 21.5363 8.40664 21.4379 6.67227ZM19.1973 17.1957C18.8316 18.1145 18.1238 18.8223 17.2004 19.1926C15.8176 19.741 12.5363 19.6145 11.0082 19.6145C9.48008 19.6145 6.19414 19.7363 4.81602 19.1926C3.89727 18.827 3.18945 18.1191 2.81914 17.1957C2.2707 15.8129 2.39727 12.5316 2.39727 11.0035C2.39727 9.47539 2.27539 6.18945 2.81914 4.81133C3.18477 3.89258 3.89258 3.18477 4.81602 2.81445C6.19883 2.26602 9.48008 2.39258 11.0082 2.39258C12.5363 2.39258 15.8223 2.2707 17.2004 2.81445C18.1191 3.18008 18.827 3.88789 19.1973 4.81133C19.7457 6.19414 19.6191 9.47539 19.6191 11.0035C19.6191 12.5316 19.7457 15.8176 19.1973 17.1957Z"
    fill="currentColor"
  />,
);

export default IconInstagram;
