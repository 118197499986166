import React from 'react';
import { Seller, Product } from 'types/schema';
import SellerSkeleton from 'components/skeleton-screens/SellerSkeleton';
import { SellerBanner } from 'components/seller';
import ProductCard from 'components/product/ProductCard';
import {
  getPrimaryProductShowcase,
  getProductPriceBySelectedVariants,
} from 'utils/product';
import { Stack } from 'components/Stack';
import { Card } from 'components/Card';
import { gtagViewItemEvent } from 'libs/gtag';
import { isButtonPressed } from 'utils/event';
import { Footer } from 'components/Footer';
import tw from 'twin.macro';

interface Props {
  data?: Seller;
}

const SellerPage = ({ data }: Props) => {
  if (!data) {
    <SellerSkeleton />;
  }

  function trackProductView(p: Product) {
    gtagViewItemEvent({
      items: [{ name: p.name, id: p.id, brand: p?.seller?.name }],
    });
  }

  return (
    <>
      <Stack css={tw`-mt-px`} spacing={2}>
        <SellerBanner
          avatar={data?.image?.url || ''}
          name={data?.name || ''}
          sold={data?.total_sale || 0}
          imgUrl="/img/seller/banner.svg"
          desc={data?.description || ''}
        />

        <Card title="Sản phẩm" css={tw`pb-0`}>
          <div css={tw`flex flex-wrap -mx-2`}>
            {(data?.products || []).map((p) => {
              const {
                name,
                id,
                slug,
                total_sale,
                // @ts-ignore
                product_prices,
              } = p as Required<Product>;
              const thumbnail =
                getPrimaryProductShowcase(p)?.photo_url || '/img/fallback.png';

              const price = getProductPriceBySelectedVariants(
                product_prices,
                [],
              );

              return (
                <div
                  css={tw`w-1/2 mb-5 px-2 focus:outline-none`}
                  key={id}
                  onClick={() => trackProductView(p)}
                  onKeyDown={(e) => {
                    if (isButtonPressed(e)) {
                      trackProductView(p);
                    }
                  }}
                  role="button"
                  tabIndex={0}
                >
                  <ProductCard
                    saleCount={total_sale}
                    name={name}
                    originalPrice={price?.original_price}
                    price={price?.price}
                    id={id}
                    slug={slug}
                    thumbnail={thumbnail}
                    sellerSlug={data?.slug || ''}
                  />
                </div>
              );
            })}
          </div>
        </Card>
      </Stack>
      <Footer />
    </>
  );
};

export default SellerPage;
