import React from 'react';
import { ProductItem } from './type';
import { Image } from 'components/Image';
import { Heading, Text } from 'components/typography';
import { Stack } from '../Stack';
import Link from 'next/link';
import { ROUTES } from 'constants/routes';
import Currency from 'components/Currency';
import { formatNumber } from 'utils/number';
import tw from 'twin.macro';

interface Props extends ProductItem {}

const ProductCard = ({
  saleCount,
  name,
  price,
  thumbnail,
  sellerSlug,
  slug,
  originalPrice = 0,
}: Props) => {
  return (
    <Stack spacing={2}>
      <Link href={ROUTES.PRODUCT()} as={ROUTES.PRODUCT({ sellerSlug, slug })}>
        <a>
          <Image
            src={thumbnail}
            alt="khung ảnh dán tường Artzy"
            isLazy
            width={161}
            height={144}
            css={tw`flex-none rounded-sm`}
            fallbackSrc="/img/fallback.png"
          />
        </a>
      </Link>
      <Stack spacing={1}>
        <Heading
          as="h3"
          css={tw`text-base font-medium leading-snug text-gray-800
        `}
        >
          <Link
            href={ROUTES.PRODUCT()}
            as={ROUTES.PRODUCT({ sellerSlug, slug })}
          >
            <a>{name}</a>
          </Link>
        </Heading>

        <Text css={tw`text-sm text-gray-500`}>
          Đã bán {formatNumber(saleCount || 0)}
        </Text>
      </Stack>
      <div css={tw`flex items-center`}>
        <Currency css={tw`text-primary mr-2`}>{price}</Currency>
        {originalPrice > price && (
          <Currency htmlAs="del" css={tw`text-sm text-gray-500 align-middle`}>
            {originalPrice}
          </Currency>
        )}
      </div>
    </Stack>
  );
};

export default ProductCard;
