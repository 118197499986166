import React from 'react';
import createIcon from './createIcon';

const IconFacebook = createIcon(
  <path
    d="M22.5 2.65781V21.3375C22.5 21.9797 21.9797 22.4953 21.3422 22.4953H15.9891V14.3672H18.7172L19.125 11.1984H15.9844V9.17344C15.9844 8.25469 16.2375 7.63125 17.5547 7.63125H19.2328V4.79531C18.9422 4.75781 17.9484 4.66875 16.7859 4.66875C14.3672 4.66875 12.7078 6.14531 12.7078 8.85938V11.1984H9.97031V14.3672H12.7078V22.5H2.65781C2.02031 22.5 1.5 21.9797 1.5 21.3422V2.65781C1.5 2.02031 2.02031 1.5 2.65781 1.5H21.3375C21.9797 1.5 22.5 2.02031 22.5 2.65781Z"
    fill="currentColor"
  />,
);

export default IconFacebook;
