import React from 'react';

export function isEnterPressed<T = HTMLElement>(e: React.KeyboardEvent<T>) {
  const { key, keyCode } = e;

  return key === 'Enter' || keyCode === 13;
}

export function isSpacePress<T = HTMLElement>(e: React.KeyboardEvent<T>) {
  const { key, keyCode } = e;

  return key === ' ' || keyCode === 32;
}

export function isButtonPressed<T = HTMLElement>(e: React.KeyboardEvent<T>) {
  return isEnterPressed(e) || isSpacePress(e);
}
