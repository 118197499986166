import React from 'react';
import { ROUTES } from 'constants/routes';
import { createGlobalStyle } from 'styled-components';
import tw from 'twin.macro';
import { Heading, Text } from 'components/typography';
import { SecondaryButton } from 'components/Button';
import { NextSeo } from 'next-seo';

const GlobalStyled = createGlobalStyle`
  html{
    height: fill-available;
    height: stretch;
  }
  body{
    height: fill-available;
    height: 100vh;
    height: stretch;
 }`;

export const ErrorPage = () => {
  return (
    <>
      <NextSeo title="Trang không tồn tại | Artzy" />
      <GlobalStyled />
      <div
        css={[
          tw`absolute top-14 bottom-0 w-full flex flex-col justify-center items-center text-center pt-4 pb-16 px-5 sm:container bg-white bg-no-repeat`,
          `background-image: url('/img/404/Bottom.svg'), url('/img/404/Top.svg');
           background-position: right calc(100% + 2px), 60% 37.5%;`,
        ]}
      >
        <Heading
          css={tw`font-bold text-gray-800 text-5xl mb-5`}
          isSerif
          as="h1"
        >
          404
        </Heading>
        <Heading
          css={tw`font-bold text-lg mb-2.5 text-gray-800`}
          isSerif
          as="h4"
        >
          Trang bạn tìm kiếm không tồn tại.
        </Heading>
        <Text css={tw`text-sm text-gray-500 mb-10 font-sans`}>
          Có thể địa chỉ URL không đúng hoặc trang đã bị xoá
        </Text>
        <SecondaryButton nextHref={ROUTES.HOME} css={tw`w-40`}>
          Về trang chủ
        </SecondaryButton>
      </div>
    </>
  );
};

export default ErrorPage;
