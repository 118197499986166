import React from 'react';
import { Image } from 'components/Image';
import tw, { styled } from 'twin.macro';

interface AvatarProps {
  src: string;
  alt?: string;
  hasBorder?: boolean;
  rounded?: boolean;
  size?: SizeLevel;
}

type SizeLevel = 'sm' | 'lg';

function mapSize(size: SizeLevel) {
  switch (size) {
    case 'lg':
      return 60;
    default:
      return 34;
  }
}

const Container = styled.div<{
  hasBorder: boolean;
  htmlWidth: number;
  htmlHeight: number;
}>`
  ${tw`rounded-full overflow-hidden flex-none`}
  ${({ hasBorder = false }) => {
    return hasBorder ? tw`border border-gray-300` : '';
  }}
  width: ${(props) => props.htmlWidth}px;
  height: ${(props) => props.htmlHeight}px;
`;

export const Avatar = (props: AvatarProps) => {
  const { src, alt = '', size = 'lg', hasBorder = false, ...rest } = props;
  const avatarSize = mapSize(size);

  return (
    <Container
      hasBorder={hasBorder}
      htmlHeight={avatarSize}
      htmlWidth={avatarSize}
    >
      <Image
        src={src}
        alt={alt}
        isLazy
        width={avatarSize}
        height={avatarSize}
        {...rest}
      />
    </Container>
  );
};
