export function addZeroPrefix(base: number) {
  return base.toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false,
  });
}

export function formatNumber(num: number) {
  return num.toLocaleString();
}
