import React from 'react';
import { Text } from 'components/typography';
import IconFacebook from 'components/icons/IconFacebook';
import IconInstagram from 'components/icons/IconInstagram';
import { Stack } from 'components/Stack';
import Link from 'next/link';
import { ROUTES } from 'constants/routes';
import { VisuallyHidden } from 'components/VisuallyHidden';
import {
  FACEBOOK_LINK,
  INSTAGRAM_LINK,
  EMAIL_LINK,
} from 'constants/static-links';
import tw from 'twin.macro';

export const Footer = () => {
  return (
    <footer>
      <Stack
        spacing={2}
        css={tw`text-center text-xs py-4 px-6 bg-no-repeat bg-right-bottom leading-4`}
        style={{
          backgroundImage: `url("img/background.png")`,
          backgroundColor: `rgb(247, 247, 248)`,
        }}
      >
        {/* <img
          src="/img/confirmation.png"
          alt="Confirmation"
          height="32"
          width="87"
          css={tw`block m-auto`}
        /> */}
        <Text>Công ty cổ phần Decor22</Text>
        <Text css={tw`text-gray-500`}>
          Địa chỉ: 81 Đào Trí, Phường Hoà Cường Nam, Quận Hải Châu, Đà Nẵng,
          Việt Nam
        </Text>
        <Text css={tw`text-gray-500`}>MST: 0402037592</Text>
        <Text css={tw`text-gray-500`}>
          Thông tin liên hệ:{' '}
          <a href={EMAIL_LINK} css={tw`text-blue-500`}>
            xinchao@artzy.vn
          </a>
        </Text>
        <Stack
          isInline
          spacing={4}
          justify="center"
          align="center"
          css={tw`mt-4`}
        >
          <a
            href={FACEBOOK_LINK}
            css={tw`text-gray-500`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <IconFacebook aria-hidden="true" />
            <VisuallyHidden>Facebook</VisuallyHidden>
          </a>
          <a
            href={INSTAGRAM_LINK}
            css={tw`text-gray-500`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <IconInstagram aria-hidden="true" />
            <VisuallyHidden>Instagram</VisuallyHidden>
          </a>
        </Stack>
      </Stack>
      <div css={tw`bg-gray-800 text-gray-400 text-center py-4 m-auto text-xs`}>
        <div
          css={tw`flex justify-center divide-x divide-gray-500 underline leading-none`}
        >
          <Link href={ROUTES.STATIC_PAGE('CHINH_SACH_DIEU_KHOAN')}>
            <a css={tw`pr-2.5 focus:text-white`}>Chính sách & Điều khoản</a>
          </Link>
          <Link href={ROUTES.STATIC_PAGE('QUY_CHE_HOAT_DONG')}>
            <a css={tw`px-2.5 focus:text-white`}>Quy chế hoạt động</a>
          </Link>
          <Link href={ROUTES.STATIC_PAGE('VE_CHUNG_TOI')}>
            <a css={tw`pl-2.5 focus:text-white`}>Về Artzy.vn</a>
          </Link>
        </div>
        <Text css={tw`mt-4`}>
          ©{new Date().getFullYear()} Artzy. All rights reserved.
        </Text>
      </div>
    </footer>
  );
};
